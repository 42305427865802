export function areDatesFromSameDay(dates) {
  if (!dates.length) return false;

  const firstDate = new Date(dates[0]);
  return dates.every((date) => {
    const currentDate = new Date(date);
    return (
      currentDate.getDate() === firstDate.getDate() &&
      currentDate.getMonth() === firstDate.getMonth() &&
      currentDate.getFullYear() === firstDate.getFullYear()
    );
  });
}

export function dayFormat(date) {
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
}

export function timeFormat(date) {
  let hours = date.getHours();
  const am_pm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${hours} ${am_pm}`;
}
