export const transformResponse = {
  getMentorOverview: (response) => {
    return {
      ...response,
      results: response.results.map((mentor) => ({
        ...mentor,
        metadata: JSON.parse(mentor.metadata),
        seo_tags: JSON.parse(mentor.seo_tags),
      })),
    };
  },
};
