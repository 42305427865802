import { createApi } from '@reduxjs/toolkit/query/react';

import { queryParams } from '../query-params';
import { fetchBaseQuery } from '../fetch-base-query';
import { transformResponse } from './transform-response';
import { endpoints } from '../../../modules/mentors-overview/endpoints';

export const mentorsOverviewApiSlice = createApi({
  reducerPath: 'mentorsOverviewApiSlice',

  baseQuery: fetchBaseQuery,

  endpoints: (builder) => ({
    getMentorsOverview: builder.query({
      query: (args) => queryParams(endpoints.getMentorsOverview, args),
      transformResponse: transformResponse.getMentorOverview,
    }),
  }),
});

export const { useGetMentorsOverviewQuery } = mentorsOverviewApiSlice;
