import { createApi } from '@reduxjs/toolkit/query/react';

import { queryParams } from './query-params';
import { fetchBaseQuery } from '../fetch-base-query';
import { transformResponse } from './response-transformers';
import { endpoints } from '../../../modules/analytics/endpoints';

export const analyticsApiSlice = createApi({
  reducerPath: 'analyticsApiSlice',

  baseQuery: fetchBaseQuery,

  endpoints: (builder) => ({
    getConversationVolume: builder.query({
      query: (args) => queryParams(endpoints.getConversationVolume, args),
      transformResponse: transformResponse.getConversationVolume,
    }),

    getUserRatings: builder.query({
      query: (args) => queryParams(endpoints.getUserRatings, args),
      transformResponse: transformResponse.getUserRatings,
    }),

    getAnalyticsOverviewSummary: builder.query({
      query: (args) => queryParams(endpoints.getAnalyticsOverviewSummary, args),
    }),

    getTopicsAnalytics: builder.query({
      query: (args) => queryParams(endpoints.getTopicsAnalytics, args),
      transformResponse: transformResponse.getTopicsAnalytics,
    }),

    getUserSentiment: builder.query({
      query: (args) => queryParams(endpoints.getUserSentiment, args),
      transformResponse: transformResponse.getUserSentiment,
    }),

    getAverageMessagesPerSession: builder.query({
      query: (args) =>
        queryParams(endpoints.getAverageMessagesPerSession, args),
      transformResponse: transformResponse.getAverageMessagesPerSession,
    }),

    getUserMetricsSummary: builder.query({
      query: (args) => queryParams(endpoints.getUserMetricsSummary, args),
    }),

    getUserCohortsOverTime: builder.query({
      query: (args) => queryParams(endpoints.getUserCohortsOverTime, args),
      transformResponse: transformResponse.getUserCohortsOverTime,
    }),

    getUserMetricsPie: builder.query({
      query: (args) => queryParams(endpoints.getUserMetricsPie, args),
      transformResponse: transformResponse.getUserMetricsPie,
    }),

    getRegisteredUsers: builder.query({
      query: (args) => queryParams(endpoints.getRegisteredUsers, args),
      transformResponse: transformResponse.getRegisteredUsers,
    }),

    getUniqueUsers: builder.query({
      query: (args) => queryParams(endpoints.getUniqueUsers, args),
      transformResponse: transformResponse.getUniqueUsers,
    }),

    getMostEngagedUsers: builder.query({
      query: (args) => queryParams(endpoints.getMostEngagedUsers, args),
      transformResponse: transformResponse.getMostEngagedUsers,
    }),

    getTopTopics: builder.query({
      query: (args) => queryParams(endpoints.getTopTopics, args),
      transformResponse: transformResponse.getTopTopics,
    }),

    getTopicsSummary: builder.query({
      query: (args) => queryParams(endpoints.getTopicsSummary, args),
    }),

    getSuggestedTopics: builder.query({
      query: (args) => queryParams(endpoints.getSuggestedTopics, args),
    }),

    getTranscriptFilters: builder.query({
      query: (args) => queryParams(endpoints.getTranscriptFilters, args),
    }),

    getTranscripts: builder.query({
      query: (args) => queryParams(endpoints.getTranscripts, args),
    }),

    getTranscriptsSummary: builder.query({
      query: (args) => queryParams(endpoints.getTranscriptsSummary, args),
    }),
  }),
});

export const {
  useGetConversationVolumeQuery,
  useGetUserRatingsQuery,
  useGetAnalyticsOverviewSummaryQuery,
  useGetTopicsAnalyticsQuery,
  useGetUserSentimentQuery,
  useGetAverageMessagesPerSessionQuery,
  useGetUserMetricsSummaryQuery,
  useGetUserCohortsOverTimeQuery,
  useGetUserMetricsPieQuery,
  useGetRegisteredUsersQuery,
  useGetUniqueUsersQuery,
  useGetMostEngagedUsersQuery,
  useGetTopTopicsQuery,
  useGetTopicsSummaryQuery,
  useGetSuggestedTopicsQuery,
  useGetTranscriptFiltersQuery,
  useGetTranscriptsQuery,
  useGetTranscriptsSummaryQuery,
} = analyticsApiSlice;
